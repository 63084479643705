import React, {useEffect, useRef} from 'react';
/*
const Animation = () => {

  var TweenMax       = window.TweenMax;
  var TimelineMax    = window.TimelineMax;
  var MorphSVGPlugin = window.MorphSVGPlugin;
  var Linear         = window.Linear;
  var Power0         = window.Power0;
  var Power1         = window.Power1;
  var Power2         = window.Power2;
  var Power3         = window.Power3;
  var Power4         = window.Power4;
  var Elastic        = window.Elastic;
  var SlowMo         = window.SlowMo;
  var RoughEase      = window.RoughEase;
  
  const container_ref = useRef(null);

  useEffect((function () {
    function f(a, b, d, f) {
      for (var g = (h = 0); g < a; g++) {
        var c = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        isFirefox ? mainSVG.appendChild(c) : b.appendChild(c);
        g > h * p && h++;
        c.setAttributeNS(null, "fill", maskValueArr[h]);
        c.setAttributeNS(null, "width", 16);
        c.setAttributeNS(null, "rx", 16);
        c.setAttributeNS(null, "ry", 16);
        c.setAttributeNS(null, "height", 16);
        TweenMax.set(c, {transformOrigin: "50% 50%", scaleY: 1 - g / 100});
        var e = new TimelineMax();
        e.to(c, 1.52, {
          bezier: {type: "cubic", values: d, autoRotate: !0},
          ease  : Linear.easeNone
        }).to(c, 0.1, {alpha: 0}, "-=0.1");
        f.add(e, g / 200);
        isFirefox && b.appendChild(c);
      }
    }

    function d(a, b) {
      return Math.floor(Math.random() * (b - a + 1) + a);
    }

    var select             = function (a) {
      return document.querySelector(a);
    };
    var selectAll          = function (a) {
      return document.querySelectorAll(a);
    };
    var container          = container_ref.current;//select(".container");
    console.log(container);
    var mainSVG            = select(".mainSVG");
    var merryGroup         = select("#merryGroup");
    var springStroke1      = select("#springStroke1");
    var springStroke2      = select("#springStroke2");
    var springStroke3      = select("#springStroke3");
    var particleContainer  = select("#particleContainer");
    var strokeMask1        = select("#strokeMask1");
    var strokeMask2        = select("#strokeMask2");
    var strokeMask3        = select("#strokeMask3");
    var allTextMasks       = selectAll(".textMask *");
    var maskValueArr       = "#111 #222 #333 #444 #555 #666 #777 #888 #999 #aaa #bbb #ccc #ddd #eee #FFF"
      .split(" ")
      .reverse();
    var particleColorArray = ["#F3F1E2"];
    var particleTypeArray  = [
      "#flake1",
      "#flake2",
      "#flake3",
      "#star",
      "#heart"];
    var particlePool       = [];
    var particleCount      = 0;
    var numParticles       = 512;
    var isFirefox          = -1 < navigator.userAgent.toLowerCase().indexOf("firefox");
    TweenMax.set(container, {
      position: "absolute",
      top     : "50%",
      left    : "50%",
      xPercent: -50,
      yPercent: -50
    });
    TweenMax.set("svg", {visibility: "visible"});
    TweenMax.set(particleContainer, {x: 0, y: 0});
    var k = new TimelineMax({paused: !0}),
        l = new TimelineMax({paused: !0}),
        m = new TimelineMax({paused: !0}),
        h,
        p = Math.round(100 / maskValueArr.length),
        q = MorphSVGPlugin.pathDataToBezier(springStroke1.getAttribute("d"), {
          offsetX: -8,
          offsetY: -8
        }),
        n = MorphSVGPlugin.pathDataToBezier(springStroke2.getAttribute("d"), {
          offsetX: -8,
          offsetY: -8
        }),
        r = MorphSVGPlugin.pathDataToBezier(springStroke3.getAttribute("d"), {
          offsetX: -8,
          offsetY: -8
        }),
        e = new TimelineMax({repeat: -1, paused: !0});
    e.to("#dot", 0.4, {attr: {cy: 200, rx: 12, ry: 12}, ease: Power2.easeOut})
      .to("#dot", 0.3, {attr: {cy: 300}, ease: Power2.easeIn})
      .set("#splashRing", {alpha: 1})
      .to("#dot", 1.2, {
        attr : {rx: 76, ry: 76},
        alpha: 0,
        ease : Power1.easeOut
      })
      .to(
        "#splashRing",
        1.2,
        {attr: {r: 50}, strokeWidth: 0, ease: Power1.easeOut},
        "-=1.2"
      )
      .to(k, 3, {time: 2.33, ease: Power2.easeOut}, "-=1.2")
      .to(l, 3.9, {time: 2.33, ease: Power2.easeOut}, "-=2.9")
      .staggerFrom(
        "#merryGroup path",
        0.9,
        {
          rotation       : -95,
          alpha          : 0,
          transformOrigin: "50% 150%",
          ease           : Elastic.easeOut.config(2, 0.8)
        },
        0.036,
        "-=3.3"
      )
      .to(m, 1, {time: 2.1, ease: SlowMo.ease.config(0.1, 0.86, !1)}, "-=2.9")
      .to(
        particleContainer,
        1.4,
        {
          bezier  : {type: "cubic", values: n, autoRotate: !1},
          onUpdate: function (a) {
            a = particlePool[particleCount];
            TweenMax.set(a, {
              x              : particleContainer._gsTransform.x,
              y              : particleContainer._gsTransform.y,
              scale          : d(1, 15) / 10,
              transformOrigin: "50% 50%"
            });
            new TimelineMax().to(a, d(3, 6), {
              physics2D    : {velocity: d(5, 10), angle: 0, gravity: d(23, 577)},
              scale        : 0,
              rotation     : d(180, 780),
              onStart      : function (a) {
                TweenMax.killTweensOf(a, {opacity: !0});
                TweenMax.fromTo(
                  a,
                  0.7,
                  {alpha: 1},
                  {
                    alpha : Math.random(),
                    ease  : RoughEase.ease.config({
                      template : Power0.easeNone,
                      strength : 3,
                      points   : 6,
                      taper    : "both",
                      randomize: !0,
                      clamp    : !1
                    }),
                    repeat: -1
                  }
                );
              },
              onStartParams: [a]
            });
            particleCount++;
            particleCount = particleCount >= numParticles ? 0 : particleCount;
          },
          ease    : Linear.easeNone
        },
        "-=4"
      )
      .from("#letterI", 0.76, {y: -200, alpha: 0, ease: Power4.easeIn}, "-=2.7")
      .from(
        "#letterI",
        1,
        {
          rotation       : 23,
          transformOrigin: "50% 100%",
          ease           : Elastic.easeOut.config(1, 0.3)
        },
        "-=2.2"
      )
      .staggerTo(
        selectAll(".endFlake"),
        0.6,
        {
          cycle   : {
            x              : [
              -10,
              0,
              10],
            y              : [
              200,
              100,
              300],
            transformOrigin: [
              "50% 50%",
              "50% 150%"]
          },
          alpha   : 0,
          rotation: d(200, 360),
          ease    : SlowMo.ease.config(0.1, 0.4, !1)
        },
        0.02,
        "-=1.95"
      )
      .staggerTo("#merryGroup *", 0.6, {alpha: 0}, 0.05)
      .to("#letterI", 0.6, {alpha: 0}, "-=0.6");
    (function () {
      for (var a = numParticles, b; -1 < --a;) {

        b = document.createElementNS("http://www.w3.org/2000/svg", "use");
        b.setAttributeNS(
          "http://www.w3.org/1999/xlink",
          "xlink:href",
          particleTypeArray[a % particleTypeArray.length]
        );
        mainSVG.appendChild(b);
        b.setAttributeNS(
          null,
          "fill",
          particleColorArray[a % particleColorArray.length]
        );
        b.setAttributeNS(null, "opacity", 0);
        b.setAttribute("class", "particle");
        particlePool.push(b);
      }
    })();
    f(100, strokeMask1, q, k);
    f(100, strokeMask2, n, l);
    f(100, strokeMask3, r, m);
    e.timeScale(0.8);
    TweenMax.delayedCall(2, function () {
      e.play();
    });
  }),[]);
  
  
  return (
    <div className="container" id={"animation_container"} ref={container_ref}>
      <svg className="mainSVG" width="600" height="600" viewBox="0 0 600 600">
        <ellipse id="dot" rx="0" ry="4" cx="300" cy="580" fill="#F3F1E2"/>
        <circle id="splashDot" r="0" cx="300" cy="300" fill="#F3F1E2"/>
        <circle opacity="0" id="splashRing" r="6" cx="300" cy="300" fill="none" stroke="#F3F1E2" stroke-width="12"/>
        <defs>
          <circle id="circ" r="1"/>
          <path id="star" d="M4.55 0l1.4 2.85 3.15.45-2.28 2.22.54 3.13-2.81-1.48-2.81 1.48.53-3.13L0 3.3l3.14-.45z"/>
          <path id="cross" d="M4 3.5L2.5 2 4 .5 3.5 0 2 1.5.5 0 0 .5 1.5 2 0 3.5l.5.5L2 2.5 3.5 4z"/>
          <path id="heart" d="M2.9 0c-.37 0-.7.18-.9.47C1.8.18 1.47 0 1.1 0 .49 0 0 .49 0 1.1 0 2.6 1.56 4 2 4s2-1.4 2-2.9C4 .49 3.51 0 2.9 0z"/>
          <path id="flake1"
                d="M5 2.1l.46-.19-.08-.71.64-.3-.09-.7.41-.2.25.38-.42.56.4.58-.42.57.3.4.49.06.28-.65.71.06.28-.65.45.03.03.45-.65.28.06.71-.65.27.06.5.4.3.57-.42.58.4.56-.42.38.25-.2.41-.7-.09-.3.64-.71-.08L7.9 5l.19.46.71-.08.3.64.7-.09.2.41-.38.25-.56-.42-.58.4-.57-.42-.4.3-.06.49.65.28-.06.71.65.28-.03.45-.45.03-.28-.65-.71.06-.27-.65-.5.06-.3.4.42.57-.4.58.42.56-.25.38-.41-.2.09-.7-.64-.3.08-.71L5 7.9l-.46.19.08.71-.64.3.09.7-.41.2-.25-.38.42-.56-.4-.58.42-.57-.3-.4-.49-.06-.28.65-.71-.06-.28.65-.45-.03-.03-.45.65-.28-.06-.71.65-.27-.06-.5-.4-.3-.57.42-.58-.4-.56.42L0 6.34l.2-.41.7.09.3-.64.71.08L2.1 5l-.19-.46-.71.08-.3-.64-.7.09-.2-.41.38-.25.56.42.58-.4.57.42.4-.3.06-.49-.65-.28.06-.71-.65-.28.03-.45.45-.03.28.65.71-.06.27.65.5-.06.3-.4-.42-.57.4-.58-.42-.56.25-.38.41.2-.09.7.64.3-.07.73z"/>
          <path id="flake2"
                d="M5 2.29l1.48-.08L6.36.17 7.5 0l.42 1.07-1.82.92.67 1.32.81 1.24 1.7-1.12.72.9-.72.9-1.7-1.12-.81 1.24-.67 1.32 1.82.92-.42 1.07-1.14-.17.12-2.04L5 6.38l-1.48.07.12 2.04-1.14.17-.42-1.07 1.82-.92-.67-1.32-.81-1.24-1.7 1.12-.72-.9.72-.9 1.7 1.12.81-1.24.67-1.32-1.82-.92L2.5 0l1.14.17-.04 2.12z"/>
          <path id="flake3"
                d="M5 3.3l.51-.45-.06-.91.71-.57L6.1.46 6.55 0l.09.64-.58.69.23.88-.58.7.15.67.68-.07.48-.76.91-.06.48-.77.64-.1-.3.57-.88.22-.33.84-.88.23-.27.63.58.34.85-.33.76.49.84-.34.58.29-.58.28-.84-.34-.76.49-.85-.33-.58.35.27.62.88.23.32.84.89.23.29.57-.63-.11-.48-.77-.91-.05L6.54 6l-.68-.06-.15.66.58.7-.23.88.58.7-.1.63-.44-.46.06-.9-.71-.58.06-.91L5 6.21l-.51.45.06.91-.71.57.06.91-.45.46-.09-.63.58-.7-.23-.88.58-.7-.15-.66-.68.06-.48.77-.91.05-.48.77-.64.1.3-.56.88-.23.33-.84.88-.23.27-.62-.58-.35-.85.33-.76-.49-.84.34L0 4.75l.58-.28.84.34.76-.49.85.33.58-.35-.27-.62-.88-.22-.32-.85-.89-.22-.29-.57.63.1.48.77.91.05.48.77.68.07.15-.67-.58-.7.23-.88-.58-.69.1-.64.44.46-.06.91.71.57-.06.92z"/>
          <mask id="merryMask">
            <rect className="merryMask" y="265" fill="#FFF" width="100%" height="60"/>
          </mask>
        </defs>
        <mask id="strokeMask1"/>
        <mask id="strokeMask2"/>
        <mask id="strokeMask3"/>
        <g mask="url(#strokeMask1)" stroke="#F3F1E2">
          <path id="springStroke1" fill="none" stroke-width="32" stroke-miterlimit="10"
                d="M295.79 306.05c4.88-40 59.64-50.35 89.11-30.94 28.87 19.02 36.98 66.23 9.7 92.82-28.82 28.09-95.93 25.53-125.28 12.41-45.16-20.19-64.58-75.02-50.54-113.88 24.37-67.46 78.03-82.94 124.6-85.46 81.7-4.41 135.04 48.72 138.78 52.04C533.59 278.71 540.15 305 553.67 340"/>
        </g>
        <g mask="url(#strokeMask2)" stroke="#F3F1E2">
          <path id="springStroke2" fill="none" stroke-width="32" stroke-miterlimit="10"
                d="M301 299c-8.88-30.88-22.07-52.82-31.33-66-17.16-24.42-38.97-55.44-76-60.67-18.91-2.67-34.3 2.45-41.33 5.33-31.61 12.96-55.3 47.33-56.67 80-2.14 51.11 51.95 61.79 56.67 122.67.49 6.36 3.73 57.6-30.67 80-26.63 17.34-63.99 9.68-86-7.33C-8.02 419.23-2.38 340.44 33 297.67c44.67-54 125.35-28.28 158-12.67 89.2 42.67 161.66 21.25 242.67-8 55.39-20 122.28 9.4 156 34"/>
        </g>
        <g mask="url(#strokeMask3)" stroke="#F3F1E2">
          <path id="springStroke3" fill="none" stroke-width="32" stroke-miterlimit="10" d="M467.82 270.07c93.65-33.69 90.62-162.02 89.85-169.4-.22-2.15-.86-8.25-4.67-10 0 0-3.04-1.4-8 1.33-9.43 5.19-9.23 122.41-7.33 222"/>
        </g>


        <g id="merryGroup" fill="#F3F1E2">

          <g>
            <path className="cls-2"
                  d="M239.25,230.7v12.5c-6.51,3.36-11.84,4.24-17.11,4.24-15.13,0-25.81-11.04-25.81-25.59,0-15.72,11.55-26.83,27.27-26.83,5.19,0,9.87,.58,14.69,2.63l-1.68,9.5c-5.04-2.41-8.92-3.14-13.01-3.14-9.21,0-17.03,6.07-17.03,17.84,0,10.67,7.24,16.59,15.64,16.59,3,0,5.99-.44,7.68-1.17v-9.58h-12.5l1.24-8.55h20.98c-.22,2.63-.37,7.46-.37,11.55Z"/>
            <path className="cls-2"
                  d="M257.74,238.08c-1.17,3.29-1.97,4.75-3.07,8.77h-10.67l23.76-53.37,24.05,53.37h-10.82c-1.24-4.31-2.05-6.36-3.44-8.77h-19.81Zm14.18-13.09c-1.83-4.46-3.14-7.9-4.02-10.82h-.29c-1.1,3.29-2.34,6.73-3.8,10.45l-2.05,5.04h12.06l-1.9-4.68Z"/>
            <path className="cls-2"
                  d="M337.21,230.7v12.5c-6.51,3.36-11.84,4.24-17.11,4.24-15.13,0-25.81-11.04-25.81-25.59,0-15.72,11.55-26.83,27.27-26.83,5.19,0,9.87,.58,14.69,2.63l-1.68,9.5c-5.04-2.41-8.92-3.14-13.01-3.14-9.21,0-17.03,6.07-17.03,17.84,0,10.67,7.24,16.59,15.65,16.59,3,0,5.99-.44,7.68-1.17v-9.58h-12.5l1.24-8.55h20.98c-.22,2.63-.37,7.46-.37,11.55Z"/>
            <path className="cls-2"
                  d="M353.65,195.61l17.47,18.86c4.02,4.39,6.94,8.04,8.63,10.09l.29-.07c-.29-4.9-.29-7.46-.29-8.92v-19.96h9.07v52.42l-33.63-36.33-.29,.22c.22,6.14,.29,12.28,.29,15.94v19.01h-9.06v-39.7c0-3.95-.22-7.16-1.02-11.55h8.55Z"/>
            <path className="cls-2" d="M426.17,195.61l-1.32,8.55h-17.33v11.62h15.5l-.73,8.55h-14.77v13.96h19.45l-1.1,8.55h-28v-39.7c0-3.95-.22-7.46-.73-11.55h29.02Zm-14.69-13.38h11.19l-7.97,9.8h-6.65l3.44-9.8Z"/>
          </g>
        </g>

        <g id="particleContainer"/>
        <g id="letterI">
          <path fill="#F3F1E2"
                d="M533.19 316.27c0-2.16 1.74-3.9 3.9-3.9 2.1 0 3.84 1.74 3.84 3.9s-1.74 3.9-3.84 3.9c-2.16 0-3.9-1.74-3.9-3.9zm.78-36.23c0-1.74 1.38-3.12 3.12-3.12 1.68 0 3.06 1.38 3.06 3.12v23.76c0 1.68-1.38 3.06-3.06 3.06-1.74 0-3.12-1.38-3.12-3.06v-23.76z"/>
          <use className="endFlake" fill="#F3F1E2" xlinkHref="#flake2" x="532" y="313"/>
          <use className="endFlake" fill="#F3F1E2" xlinkHref="#flake3" x="532" y="313"/>
          <use className="endFlake" fill="#F3F1E2" xlinkHref="#flake3" x="532" y="313"/>
        </g>
      </svg>


    </div>
  );
};
*/

const Animation = (props) => {

    const lang = localStorage.getItem("lang");

    return (
        <>
            <div id={"animation_overlay"}></div>
            <div id={"animation_wrapper"}>
                <video width="600" height="100%" autoPlay muted playsInline onEnded={props.handleOnEnd}>
                    <source src={`/video/${lang}/${lang}.mov`} type='video/mp4; codecs="hvc1"'/>
                    <source src={`/video/${lang}/${lang}.webm`} type="video/webm"/>
                </video>
            </div>
        </>
    );
};


export default Animation;